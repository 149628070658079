import React, { useEffect, useState } from "react";
import { BookingToBillingFeaturesType } from "../../assets/Types/AcfGroupTypes/BookingToBilling.type";
import { Link } from "gatsby";

import useWindowSize from "@hooks/useWindowSize";

import styles from "./BookingToBilling.module.scss";

const BookingToBilling: React.FC<{
  bookingToBillingFeatureList: BookingToBillingFeaturesType[];
}> = ({ bookingToBillingFeatureList }) => {
  const [hoverEl, setHoverEl] = useState("");
  const { width: windowWidth } = useWindowSize();
  const [isSmallScreen, setSmallScreen] = useState(false);

  useEffect(() => {
    if (windowWidth && windowWidth < 1024) {
      setSmallScreen(true);
    } else {
      setSmallScreen(false);
    }
  }, [windowWidth]);

  useEffect(() => {
    if (bookingToBillingFeatureList && bookingToBillingFeatureList.length > 0) {
      const currentHoveredEl =
        bookingToBillingFeatureList[0]?.headline.toLowerCase() as string;
      setHoverEl(currentHoveredEl);
    }
  }, [bookingToBillingFeatureList]);

  const currentEl = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!isSmallScreen) {
      const { iconIndex } = e.currentTarget.dataset;
      if (iconIndex) {
        setHoverEl(iconIndex.toLowerCase());
      }
    }
  };

  const currentElNav = (e: React.MouseEvent<HTMLDivElement>) => {
    if (isSmallScreen) {
      const { navIndex } = e.currentTarget.dataset;
      if (navIndex) {
        setHoverEl(navIndex.toLowerCase());
      }
    }
  };

  return (
    <div className={`${styles.b2bGrid}`}>
      <div
        className={`${styles.b2bFeaturesGrid} ${
          isSmallScreen && styles.b2bMobileNavContainer
        }`}
      >
        {bookingToBillingFeatureList &&
          bookingToBillingFeatureList.map((feature, index) => {
            let cardNavClass = "";

            if (hoverEl === feature.headline.toLowerCase()) {
              cardNavClass = `${styles.navIsHovered} ${styles.b2bMobileNavItemActive}`;
            }

            const cardLink = feature?.link ? (
              <Link className={styles.b2bCardLink} to={feature?.link!}>
                <p>{feature?.headline}</p>
              </Link>
            ) : (
              <p className={`${styles.b2bCardNoLink}`}>{feature?.headline}</p>
            );

            return (
              <div
                key={`b2b-left-${index}`}
                data-icon-index={feature?.headline.toLowerCase()}
                className={`${styles.b2bCards} ${cardNavClass}`}
                onMouseEnter={currentEl}
                data-nav-index={feature?.headline.toLowerCase()}
                onClick={currentElNav}
              >
                {isSmallScreen && feature?.headline}

                {!isSmallScreen && (
                  <>
                    <div className={styles.b2bCardTitle}>
                      <img
                        className={styles.img}
                        data-image-index={feature?.headline.toLowerCase()}
                        src={feature.icon?.mediaItemUrl}
                        alt={feature.icon.altText}
                      />
                      {cardLink}
                    </div>
                    <p>{feature.subcopy}</p>
                  </>
                )}
              </div>
            );
          })}
      </div>

      <div>
        {bookingToBillingFeatureList &&
          bookingToBillingFeatureList.map((feature, index) => {
            const imageClass =
              hoverEl === feature?.headline.toLowerCase()
                ? styles.imageIsActive
                : styles.imageIsInactive;

            const CardWrapper = feature?.link
              ? ({ children }: { children: React.ReactNode }) => (
                  <Link to={feature?.link!}>{children}</Link>
                )
              : ({ children }: { children: React.ReactNode }) => (
                  <>{children}</>
                );

            return (
              <React.Fragment key={`b2b-right-${index}`}>
                {!isSmallScreen && (
                  <span className={`${styles.b2bCardImageWrapper}`}>
                    <div
                      data-image-index={feature?.headline.toLowerCase()}
                      className={`${styles.b2bCardImage} ${styles.b2bCardPosition} ${imageClass} `}
                    >
                      <img
                        src={feature.image.mediaItemUrl}
                        alt={feature.image.altText}
                      />
                    </div>
                  </span>
                )}

                {isSmallScreen && (
                  <div>
                    <div className={`${styles.b2bMobileContent} ${imageClass}`}>
                      <CardWrapper>
                        <div className={styles.b2bCardsMobile}>
                          <div
                            className={`${styles.b2bCardTitle} ${
                              !feature?.link && styles.noLink
                            }`}
                          >
                            <img
                              className={styles.img}
                              data-image-index={feature?.headline.toLowerCase()}
                              src={feature.icon?.mediaItemUrl}
                              alt={feature.icon.altText}
                            />

                            <p>{feature?.headline}</p>
                          </div>
                          <p>{feature?.subcopy}</p>
                        </div>
                      </CardWrapper>

                      <div className={styles.b2bMobileImageContainer}>
                        <div className={styles.b2bMobileImage}>
                          <img
                            src={feature?.image?.mediaItemUrl}
                            alt={feature?.image?.altText}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </React.Fragment>
            );
          })}
      </div>
    </div>
  );
};

export default BookingToBilling;
