import { useEffect, useState } from "react";
import { builder } from "@builder.io/sdk";

type BookingToBillingQueryType = Queries.builder_BookingToBillingData;

const BookingToBillingQuery = (): BookingToBillingQueryType | null => {
  const [content, setContent] = useState<BookingToBillingQueryType | null>(
    null
  );

  useEffect(() => {
    builder
      .get("booking-to-billing")
      .promise()
      .then(res => {
        if (res.data) {
          setContent(res.data);
        }
      });
  }, []);

  return content;
};

export default BookingToBillingQuery;
